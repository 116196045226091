import { useEffect, useState } from 'react';
import { Plugins, GeolocationPosition } from '@capacitor/core';

const { Geolocation } = Plugins;

export interface GeoLocationSensorState {
    loading: boolean;
    accuracy: number | null | undefined;
    altitude: number | null | undefined;
    altitudeAccuracy: number | null | undefined;
    heading: number | null | undefined;
    latitude: number | null | undefined;
    longitude: number | null | undefined;
    speed: number | null | undefined;
    timestamp: number | null | undefined;
}

export default function useGeolocation(): GeoLocationSensorState {
    const [state, setState] = useState<GeoLocationSensorState>({
        loading: true,
        accuracy: null,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        latitude: null,
        longitude: null,
        speed: null,
        timestamp: Date.now(),
    });
    let mounted = true;
    let watchId: any;

    function onEvent(event: GeolocationPosition) {
        if (mounted && event) {
            setState({
                loading: false,
                accuracy: event.coords.accuracy,
                altitude: event.coords.altitude,
                altitudeAccuracy: event.coords.altitudeAccuracy,
                heading: event.coords.heading,
                latitude: event.coords.latitude,
                longitude: event.coords.longitude,
                speed: event.coords.speed,
                timestamp: event.timestamp,
            });
        }
    }

    useEffect(() => {
        Geolocation.getCurrentPosition()
            .then(onEvent)
            .catch((e) => console.error('Error', e));
        watchId = Geolocation.watchPosition({}, onEvent);

        return () => {
            mounted = false;
            Geolocation.clearWatch(watchId);
        };
    }, []);

    return state;
}
