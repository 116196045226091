interface Command {
    id: Id;
}

interface Meal {
    command: {
        id: Id;
    };
    status: string;
}

export default class DeliveryService {
    static getDeliveryStatus(status: string) {
        if (status.toLowerCase() == 'pending') {
            return 'En attente';
        } else if (status.toLowerCase() == 'in-progress') {
            return 'En cours';
        } else if (status.toLowerCase() == 'completed') {
            return 'Terminée';
        }
    }
    static getCommandsProgress(commands: Command[], meals: Meal[]) {
        let totalMeals = 0;
        let deliveredMeals = 0;
        commands.forEach((command) => {
            const clientMeals = meals.filter(
                (m) => m.command.id === command.id,
            );
            totalMeals += clientMeals.length;
            deliveredMeals += clientMeals.filter((m) =>
                ['delivered', 'failed'].includes(m.status),
            ).length;
        });

        return totalMeals > 0
            ? Math.round((deliveredMeals / totalMeals) * 100)
            : 0;
    }
}
