import React, { useState, useEffect } from 'react';

import { Popover, Select, Button, notification } from 'antd';
import { MoreOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import DeleteButton from 'shared/components/DeleteButton';
import moment from 'moment';

import './DeliveryDayItem.scss';
import SortListItem from 'components/SortListItem';
import useDebounce from 'shared/hooks/useDebounce';

import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import DeliveryService from 'shared/services/DeliveryService';
import ClientStatusBadge from 'shared/components/delivery/ClientStatusBadge';

interface DeliveryDayItemProps {
    delivery: Delivery;
    commands: Command[];
    onDelete?: (id: Id) => void;
    onChange: (id: Id, values: DeliveryInput) => void;
    employees: Employee[];
    clients: Client[];
    meals: Meal[];
    allowOptimize?: boolean;
}

interface Command {
    id: Id;
}

interface DeliveryInput {
    name?: string;
    deliver?: number;
    deliveryCommands?: {
        command?: Id;
        sortOrder?: number;
    }[];
}

interface Delivery {
    id: Id;
    name: string;
    color: string;
    status: string;
    startTime: string;
    deliver: {
        id: number;
    } | null;
    deliveryCommands?: {
        id: Id;
        sortOrder: number;
        command: {
            id: Id;
            client: {
                id: number;
            };
        };
    }[];
}

interface Employee {
    id: number;
    firstName: string;
    lastName: string;
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
}

interface Meal {
    command: {
        id: Id;
    };
    status: string;
}

export default function DeliveryDayItem({
    delivery,
    commands,
    meals,
    onDelete,
    onChange,
    employees,
    clients,
    allowOptimize,
}: DeliveryDayItemProps) {
    const [deliveryCommands, setDeliveryCommands] = useState(
        delivery.deliveryCommands,
    );
    const [clientsVisible, setClientsVisible] = useState(false);

    const dOnChange = useDebounce(onChange, 1000);

    useEffect(() => {
        setDeliveryCommands(delivery.deliveryCommands);
    }, [delivery.deliveryCommands]);

    const actionMenu = (allowOptimize || onDelete) && (
        <>
            {allowOptimize && <OptimizeDeliveryButton delivery={delivery} />}
            {onDelete && (
                <DeleteButton onDelete={() => onDelete(delivery.id)}>
                    Supprimer la tournée
                </DeleteButton>
            )}
        </>
    );

    function moveCommand(dragIndex: number, hoverIndex: number) {
        if (!deliveryCommands) {
            return;
        }
        let newDeliveryCommands = deliveryCommands.sort((c1, c2) =>
            c1.sortOrder > c2.sortOrder ? 1 : -1,
        );
        const defaultClient = newDeliveryCommands.find(
            (c) => c.sortOrder === dragIndex,
        );
        if (defaultClient) {
            newDeliveryCommands.splice(dragIndex, 1);
            newDeliveryCommands.splice(hoverIndex, 0, defaultClient);
            newDeliveryCommands = newDeliveryCommands.map((c, index) => ({
                ...c,
                sortOrder: index,
            }));

            setDeliveryCommands(newDeliveryCommands);

            dOnChange(delivery.id, {
                deliveryCommands: newDeliveryCommands.map((c) => ({
                    command: c.command.id,
                    sortOrder: c.sortOrder,
                })),
            });
        }
    }

    const countMeals = meals.filter(
        (m) =>
            deliveryCommands &&
            deliveryCommands.findIndex((d) => d.command.id === m.command.id) >
                -1,
    ).length;

    const isInProgress = delivery.status === 'in-progress';

    return (
        <div
            className="delivery-day-item"
            style={{ borderColor: delivery.color }}
        >
            <header
                className="__header"
                style={{ borderColor: delivery.color }}
            >
                <div
                    className="__background"
                    style={{ backgroundColor: delivery.color }}
                ></div>
                <div className="__title">{delivery.name}</div>
                {actionMenu && (
                    <Popover
                        content={actionMenu}
                        placement="right"
                        overlayClassName="delivery-day-item__action-menu"
                    >
                        <Button
                            icon={<MoreOutlined />}
                            className="__action-btn"
                            shape="circle"
                            size="small"
                        />
                    </Popover>
                )}
            </header>
            <div className="__content">
                <div className="__default-delivery-man">
                    <span className="__label">Livreur :</span>
                    <Select
                        className="__delivery-man-choice"
                        defaultValue={delivery.deliver?.id}
                        onChange={(value) => {
                            onChange(delivery.id, {
                                deliver: value,
                            });
                        }}
                    >
                        {employees?.map((employee: Employee) => {
                            return (
                                <Select.Option
                                    value={employee.id}
                                    key={employee.id}
                                >
                                    {employee.firstName} {employee.lastName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                {deliveryCommands && clients && (
                    <>
                        <div className="__key-numbers">
                            <div>{`${deliveryCommands.length} client${
                                deliveryCommands.length > 1 ? 's' : ''
                            }`}</div>
                            <div>{`${countMeals} ${
                                countMeals > 1 ? 'menus' : 'menu'
                            }`}</div>
                        </div>

                        <div
                            className={`__status --${delivery.status}`}
                            style={
                                isInProgress
                                    ? { borderColor: delivery.color }
                                    : undefined
                            }
                        >
                            {delivery.startTime && (
                                <div className="__started-at">{`Commencée ${moment(
                                    delivery.startTime,
                                ).fromNow()}`}</div>
                            )}
                            <div
                                className="__status"
                                style={
                                    isInProgress
                                        ? {
                                              backgroundColor: delivery.color,
                                              borderColor: delivery.color,
                                          }
                                        : undefined
                                }
                            >
                                {`${
                                    DeliveryService.getDeliveryStatus(
                                        delivery.status,
                                    ) ||
                                    DeliveryService.getDeliveryStatus('pending')
                                    //statusLabelMap[round.status] || statusLabelMap.pending
                                }${
                                    isInProgress
                                        ? `: ${DeliveryService.getCommandsProgress(
                                              commands.filter(
                                                  (command) =>
                                                      deliveryCommands.findIndex(
                                                          (c) =>
                                                              c.command.id ===
                                                              command.id,
                                                      ) > -1,
                                              ),
                                              meals,
                                          )}%`
                                        : ''
                                }`}
                            </div>
                        </div>

                        {deliveryCommands.length > 0 && (
                            <Button
                                className="__clients-visibility-trigger"
                                onClick={() => setClientsVisible((v) => !v)}
                            >
                                {clientsVisible
                                    ? 'Masquer les clients'
                                    : 'Voir les clients'}
                                {clientsVisible ? (
                                    <UpOutlined />
                                ) : (
                                    <DownOutlined />
                                )}
                            </Button>
                        )}
                        {clientsVisible && (
                            <div className="__client-list">
                                {deliveryCommands
                                    .sort((c1, c2) =>
                                        c1.sortOrder > c2.sortOrder ? 1 : -1,
                                    )
                                    .map((c) => {
                                        const command = commands.find(
                                            (cl) => cl.id === c.command.id,
                                        );
                                        const client = clients.find(
                                            (cl) =>
                                                cl.id === c.command.client.id,
                                        );

                                        return (
                                            client &&
                                            command && (
                                                <SortListItem
                                                    key={client.id}
                                                    id={client.id}
                                                    listId={delivery.id}
                                                    index={c.sortOrder}
                                                    onMove={moveCommand}
                                                >
                                                    {`${client.firstName} ${client.lastName}`}
                                                    <ClientStatusBadge
                                                        commands={[command]}
                                                        meals={meals.filter(
                                                            (m) =>
                                                                m.command.id ===
                                                                command.id,
                                                        )}
                                                    />
                                                </SortListItem>
                                            )
                                        );
                                    })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

const OPTIMIZE_DELIVERY = gql`
    mutation optimizeDelivery($id: UUID!) {
        optimizeDelivery(id: $id) {
            id
            deliveryCommands {
                id
                sortOrder
                command {
                    id
                }
            }
        }
    }
`;

function handleMutationError(err: Error) {
    notification.error({
        message: "Erreur lors de l'enregistrement",
        description: err.message,
    });
}
interface OptimizeDeliveryButtonProps {
    delivery: Delivery;
}
function OptimizeDeliveryButton({ delivery }: OptimizeDeliveryButtonProps) {
    const [optimizeDelivery, { loading }] = useMutation(OPTIMIZE_DELIVERY, {
        onError: handleMutationError,
    });

    return (
        <Button
            loading={loading}
            onClick={() => optimizeDelivery({ variables: { id: delivery.id } })}
        >
            Calculer le trajet
        </Button>
    );
}
