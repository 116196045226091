import React, { useContext } from 'react';
import { Modal, Form, Button, Input, notification, Col } from 'antd';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { AuthContext } from 'shared/contexts/AuthContext';
import './ChangePasswordModal.scss';
const EDIT_CLIENT = gql`
    mutation editUser($id: Int!, $input: UserInput!) {
        editUser(id: $id, input: $input) {
            id
            shouldUpdatePassword
        }
    }
`;

export default function ChangePasswordModal() {
    const { user } = useContext(AuthContext);
    const [editClient, { loading }] = useMutation(EDIT_CLIENT, {
        onCompleted: () => {
            notification.success({ message: 'Mot de passe modifié' });
        },
        onError: () => {
            notification.error({
                message: "Erreur lors de l'enregistrement",
            });
        },
    });

    if (!user?.shouldUpdatePassword) {
        return null;
    }

    return (
        <Modal
            title="Modifiez votre mot de passe"
            visible
            footer={null}
            className="change-password-modal"
            closable={false}
        >
            <Form
                layout="vertical"
                hideRequiredMark
                onFinish={(values) => {
                    editClient({
                        variables: {
                            id: user.id,
                            input: {
                                plainPassword: values.password,
                            },
                        },
                    });
                }}
            >
                <Form.Item
                    name="password"
                    label="Mot de passe"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez saisir votre mot de passe',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Confirmez le mot de passe"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez confirmer votre mot de passe',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    'Les mots de passes ne sont pas identiques',
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType="submit"
                        loading={loading}
                    >
                        Modifier
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
