import moment from 'moment';

export default class QuickbooksService {
    static getDaysCountBeforeExpiration(quickbooks: Quickbook[]) {
        const refreshTokenExpiresDate: any = quickbooks
            .map((quickbook) => quickbook.refreshTokenExpiresAt)
            .sort()
            .reverse()[0];
        return refreshTokenExpiresDate
            ? moment(refreshTokenExpiresDate).diff(moment(), 'days')
            : 0;
    }
}
