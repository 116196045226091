import { useEffect, useState } from 'react';

function debounce(func: any, wait: number, immediate?: boolean): any {
    let timeout: any;
    return function () {
        // @ts-ignore
        let context: any = this;
        let args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}

export default function useDebounce(
    func: any,
    wait: number,
    immediate?: boolean,
) {
    const [dFunc, setDFunc] = useState(() => debounce(func, wait, immediate));
    useEffect(() => {
        setDFunc(() => debounce(func, wait, immediate));
    }, [func, wait, immediate]);
    return dFunc;
}
