import React, { useCallback, useState, useMemo } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ArrayService from 'shared/services/ArrayService';
import { useMeasure } from 'react-use';
import { Scrollbars } from 'react-custom-scrollbars';

import './DeliveryDay.scss';
import { Button, Tabs, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DeliveryMap from 'shared/components/delivery/DeliveryMap';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loading from 'shared/components/Loading';
import DeliveryDayItem from './DeliveryDay/DeliveryDayItem';
import DeliveryDayList from './DeliveryDay/DeliveryDayList';
import AlertTag from 'shared/components/AlertTag';

const GET_DATA = gql`
    query getData($date: Date!, $clientType: String!) {
        clientTypes {
            code
            label
        }
        meals(date: $date, clientType: $clientType, isDelivery: true) {
            id
            date
            mealPeriod
            dishes {
                id
                dish {
                    id
                    type
                    name
                }
                quantity
            }
            command {
                id
                client {
                    id
                    firstName
                    lastName
                }
                address {
                    coordinates {
                        lat
                        lng
                    }
                }
            }
            menu {
                id
                mealDefinition {
                    id
                    name
                }
            }
            status
            failureComment
            clientIdentity {
                id
                firstname
                lastname
            }
            deliveredAt
        }
        employees(roles: ["delivery-man"]) {
            id
            firstName
            lastName
        }
        deliveries(date: $date, clientType: $clientType) {
            id
            name
            color
            deliver {
                id
            }
            deliveryCommands {
                id
                sortOrder
                command {
                    id
                    client {
                        id
                    }
                }
                deliveryPictureUrl
            }
            date
            clientType
            status
            startTime
        }
    }
`;

const EDIT_DELIVERY = gql`
    mutation editDelivery($id: UUID!, $input: DeliveryInput!) {
        editDelivery(id: $id, input: $input) {
            id
            name
            deliver {
                id
            }
            deliveryCommands {
                id
                sortOrder
                command {
                    id
                    client {
                        id
                    }
                }
            }
        }
    }
`;

const ASSIGN_COMMAND_TO_DELIVERY = gql`
    mutation assignCommandToDlivery($command: UUID!, $delivery: UUID!) {
        assignCommandToDlivery(command: $command, delivery: $delivery) {
            id
            name
            deliver {
                id
            }
            deliveryCommands {
                id
                sortOrder
                command {
                    id
                }
            }
        }
    }
`;

const GENERATE_DELIVERIES = gql`
    mutation generateDeliveries($date: Date!, $clientType: String!) {
        generateDeliveries(date: $date, clientType: $clientType) {
            id
        }
    }
`;

const DELETE_DELIVERY = gql`
    mutation deleteDelivery($id: UUID!) {
        deleteDelivery(id: $id)
    }
`;

interface DeliveryInput {
    name?: string;
    deliver?: number;
    deliveryCommands?: {
        command?: Id;
        sortOrder?: number;
    }[];
}

interface Data {
    clientTypes: Constant[];
    meals: Meal[];
    deliveries: Delivery[];
    employees: Employee[];
}

interface Delivery {
    id: Id;
    name: string;
    color: string;
    deliver: {
        id: number;
    };
    deliveryCommands: {
        id: Id;
        sortOrder: number;
        command: {
            id: Id;
            client: {
                id: number;
            };
        };
        deliveryPictureUrl: string;
    }[];
    date: string;
    clientType: string;
    status: string;
    startTime: string;
}

interface Meal {
    id: Id;
    date: string;
    mealPeriod: string;
    menu: {
        id: Id;
        mealDefinition: {
            id: Id;
            name: string;
        };
    };
    dishes: {
        id: Id;
        dish: {
            id: Id;
            type: string;
            name: string;
        };
        quantity: number;
    }[];
    clientIdentity: {
        id: Id;
        firstname: string;
        lastname: string;
    };
    command: Command;
    status: string;
    deliveredAt: string;
    failureComment: string | null;
}

interface Command {
    id: Id;
    client: {
        id: number;
        firstName: string;
        lastName: string;
    };
    address: {
        coordinates: {
            lat: number;
            lng: number;
        };
    };
}

function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function showErrorNotification(err: { message: string }, message?: string) {
    notification.error({
        message: message || "Erreur lors de l'enregistrement",
        description: err.message,
    });
}

export default function DeliveryDay() {
    const { date: rawDate, clientType } = useParams() as any;
    const date = moment(rawDate);

    const { loading, data, refetch } = useQuery<Data>(GET_DATA, {
        variables: {
            date: date.format('YYYY-MM-DD'),
            clientType,
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 10000,
    });

    const [editDelivery] = useMutation(EDIT_DELIVERY, {
        onError: showErrorNotification,
    });
    const [assignCommandToDlivery] = useMutation(ASSIGN_COMMAND_TO_DELIVERY, {
        onError: showErrorNotification,
    });
    const [generateDeliveries, { loading: generateLoading }] = useMutation(
        GENERATE_DELIVERIES,
        {
            onCompleted: refetch,
            onError: showErrorNotification,
        },
    );

    const [deleteDelivery] = useMutation(DELETE_DELIVERY, {
        onCompleted: refetch,
    });

    const [showHelp, setShowHelp] = useState(false);

    const commands = useMemo(
        () =>
            data
                ? data.meals
                      .map((m) => m.command)
                      .filter(ArrayService.filterUniqueEntity)
                : [],
        [data],
    );

    function handleClientClick(clientId: number, deliveryId: Id) {
        if (deliveryId && data) {
            const selectedCommands = commands.filter(
                (c) => c.client.id === clientId,
            );
            selectedCommands.map((command) =>
                assignCommandToDlivery({
                    variables: {
                        command: command.id,
                        delivery: deliveryId,
                    },
                }),
            );
        }
    }

    function handleCommandClick(commandId: Id, deliveryId: Id) {
        assignCommandToDlivery({
            variables: {
                command: commandId,
                delivery: deliveryId,
            },
        });
    }

    const handleDeliveryChange = useCallback(
        (id: Id, input: DeliveryInput) => {
            editDelivery({
                variables: {
                    id,
                    input,
                },
            });
        },
        [editDelivery],
    );

    const deliveryStarted = !!data?.deliveries.find(
        (d) => d.status !== 'pending',
    );

    const clientTypes = data ? data.clientTypes : [];

    return (
        <div className="edit-delivery">
            <header className="__header">
                <h2>
                    {`${capitalize(date.format('dddd'))} ${date.format(
                        'DD',
                    )} ${capitalize(date.format('MMMM'))} ${date.format(
                        'YYYY',
                    )} : ${
                        clientTypes.find((c) => c.code === clientType)?.label ||
                        ''
                    }`}
                </h2>
                {data && (
                    <UnasignedClientsAlert
                        rounds={data.deliveries}
                        commands={commands}
                    />
                )}
            </header>
            <div className="__content">
                <div className="__round-list">
                    {data && !deliveryStarted && (
                        <Button
                            shape="round"
                            className="__generate-btn"
                            onClick={() =>
                                generateDeliveries({
                                    variables: {
                                        date: date.format('YYYY-MM-DD'),
                                        clientType,
                                    },
                                })
                            }
                            loading={generateLoading}
                        >
                            Générer les tournées
                        </Button>
                    )}
                    {!data && loading && (
                        <div className="__loading">
                            <Loading />
                        </div>
                    )}
                    <DeliveryItems
                        data={data}
                        commands={commands}
                        onChange={handleDeliveryChange}
                        onDelete={
                            clientType !== 'senior'
                                ? (id) => {
                                      deleteDelivery({
                                          variables: {
                                              id: id,
                                          },
                                      });
                                  }
                                : undefined
                        }
                    />
                </div>
                <div className="__round-map">
                    <Tabs defaultActiveKey="map">
                        <Tabs.TabPane tab="Carte" key="map">
                            <DeliveryMap
                                rounds={data ? data.deliveries : []}
                                commands={commands}
                                meals={data ? data.meals : []}
                                onClientClick={
                                    clientType !== 'senior'
                                        ? handleClientClick
                                        : undefined
                                }
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Liste" key="list">
                            <DeliveryDayList
                                commands={commands}
                                deliveries={data ? data.deliveries : []}
                                meals={data ? data.meals : []}
                                onDeliveryChange={handleDeliveryChange}
                                onAssignCommand={
                                    clientType !== 'senior'
                                        ? handleCommandClick
                                        : undefined
                                }
                            />
                        </Tabs.TabPane>
                    </Tabs>
                    <div
                        className={`__help ${showHelp ? '--visible' : ''}`}
                        onClick={() => setShowHelp(false)}
                    >
                        <ArrowLeftOutlined />
                        <div className="__text">
                            Sélectionnez une tournée pour y assigner un client
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DeliveryItems({
    data,
    commands,
    onChange,
    onDelete,
}: {
    data: Data | undefined;
    commands: Command[];
    onChange: (id: Id, input: DeliveryInput) => void;
    onDelete?: (id: Id) => void;
}) {
    const [ref, { height }] = useMeasure();
    return (
        <div className="__items" ref={ref as (instance: any) => void}>
            <Scrollbars style={{ height: height-32 }}>
                {data &&
                    data.deliveries.map((delivery) => (
                        <DeliveryDayItem
                            key={delivery.id}
                            delivery={delivery}
                            commands={commands}
                            clients={data.meals
                                .map((m) => m.command.client)
                                .filter(ArrayService.filterUniqueEntity)}
                            employees={data.employees}
                            meals={data.meals}
                            onChange={onChange}
                            onDelete={onDelete}
                            allowOptimize={delivery.status === 'pending'}
                        />
                    ))}
            </Scrollbars>
        </div>
    );
}

interface UnasignedCommandsAlertProps {
    rounds: Delivery[];
    commands: Command[];
}
function UnasignedClientsAlert({
    rounds,
    commands,
}: UnasignedCommandsAlertProps) {
    const unasignedCommands = commands.filter((command) => {
        return (
            rounds.findIndex(
                (round) =>
                    round.deliveryCommands.findIndex(
                        (c) => c.command.id === command.id,
                    ) > -1,
            ) === -1
        );
    });

    if (unasignedCommands.length === 0) {
        return null;
    }

    return <AlertTag message="Regénérer la tournée" />;
}
