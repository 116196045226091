import React, { useRef, useState, useEffect } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { useClickAway } from 'react-use';
import { Input } from 'antd';

import './SortListItem.scss';

interface SortListItemProps {
    id: string | number;
    index: number;
    listId: string;
    onMove: (dragIndex: number, hoverIndex: number) => void;
    children: any;
    className?: string;
}

export default function SortListItem({
    index,
    id,
    listId,
    onMove,
    children,
    className,
}: SortListItemProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [isEdit, setIsEdit] = useState(false);
    const [newIndex, setNewIndex] = useState<string>(index + 1 + '');
    useClickAway(ref, () => {
        setIsEdit(false);
        setNewIndex(index + 1 + '');
    });
    useEffect(() => {
        setNewIndex(index + 1 + '');
    }, [index]);

    const [, drop] = useDrop({
        accept: listId,
        hover(item: any, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset
                ? clientOffset.y - hoverBoundingRect.top
                : 0;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            //moveCard(dragIndex, hoverIndex);

            onMove(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [, drag, preview] = useDrag({
        item: { type: listId, id, index },
    });

    preview(drop(ref));
    return (
        <div
            ref={ref}
            className={`sort-list-item ${className ? className : ''}`}
        >
            {isEdit ? (
                <Input
                    className="__input"
                    value={newIndex}
                    autoFocus
                    onKeyPress={(e) => {
                        if (e.nativeEvent.keyCode === 13) {
                            const i = parseInt(newIndex);
                            onMove(index, i - 1);
                            setIsEdit(false);
                        }
                    }}
                    onChange={(e) => {
                        setNewIndex(e.target.value);
                    }}
                    onFocus={(e) => e.target.select()}
                />
            ) : (
                <div
                    ref={drag}
                    className="__handle"
                    onClick={() => setIsEdit(true)}
                >
                    {index + 1}
                </div>
            )}
            <div className="__content">{children}</div>
        </div>
    );
}
