import React, { useState } from 'react';
import { Button, notification } from 'antd';

import logoIcon from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';

import { ToastActions } from 'ias-lib';
import ResetPasswordForm from 'shared/forms/ResetPasswordForm';

import './ResetPassword.scss';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';

declare interface ResetPasswordProps {
    location: any;
}

function getUrlParameter(search: string, name: string) {
    const n = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${n}=([^&#]*)`);
    const results = regex.exec(search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}
  
export default function ResetPassword({ location }: ResetPasswordProps) {
    const [fields, setFields] = useState([] as any[]);
    const [loading, setLoading] = useState<boolean>(false);
    const [displayEndMessage, setDisplayEndMessage] = useState<boolean>(false);

    const email = getUrlParameter(location.search, "email");
    const validationCode = getUrlParameter(location.search, "validation-code");

    function resetPassword (plainPassword: string) {
        setLoading(true);
        
        fetch(`${BaseUrlConstants.BASE_URL}change-password`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                validationCode,
                plainPassword
            })
        },
        ).then((res: any) => {
            if(res.ok) {
                notification.success({
                    message: 'Mot de passe modifié',
                });
                setDisplayEndMessage(true);
            } else {
                notification.error({
                    message: "Erreur lors de l'enregistrement",
                });
            }
            setLoading(false);
        }).catch((error: string) => {
            console.error(error);
            setLoading(false);
            ToastActions.createToastError(
                'Une erreur est survenue',
            )});
    };

    return (
        <div className="reset-password">
            <div className="__header">
                <div className="__logo">
                    <img
                        className="__icon"
                        src={logoIcon}
                        alt="Logo"
                    />
                    <img
                        className="__text"
                        src={logoText}
                        alt="La Cigale"
                    />
                </div>
                <div className="__catch-phrase">
                    <strong>Livraison de repas</strong> sur mesure à
                    domicile depuis <strong>1985</strong> sur{' '}
                    <strong>Nîmes</strong> et ses environs.
                </div>
                <a
                    href="https://repaslacigale.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button
                        className="__more-info-btn"
                        ghost
                        shape="round"
                        size="large"
                    >
                        En savoir plus
                    </Button>
                </a>
            </div>
            <div className="__content">
                <h1>Initialiser mon mot de passe</h1>
                <ResetPasswordForm
                    onChange={setFields}
                    fields={fields}
                    onSubmit={resetPassword}
                    loading={loading}
                />
                {displayEndMessage && <b>Votre mot de passe a bien été modifié, vous pouvez désormais fermer cette page.</b> }
            </div>
        </div>
    );
}
