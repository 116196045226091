import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import ReactDOM from 'react-dom';
import L from 'leaflet';
import { useMeasure } from 'react-use';
import useGeolocation from 'shared/hooks/useGeolocation';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';
import 'leaflet/dist/leaflet.css';
import './DeliveryMap.scss';
import { AimOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import LeafletClientMarker from './LeafletClientMarker';
import useClientClusters from './useClientClusters';
import HomeMarker, { HOME_LATLNG } from './HomeMarker';
import DeliveryPath from './DeliveryPath';
import ArrayService from '../../services/ArrayService';

interface DeliveryMapProps {
    meals: Meal[];
    rounds: Delivery[];
    commands: Command[];
    onClientClick?: (clientId: number, zoneId: Id) => void;
    geolocation?: boolean;
    locked?: boolean;
}

interface Delivery {
    id: Id;
    name: string;
    color: string;
    deliveryCommands: {
        sortOrder: number;
        command: {
            id: string;
            client: {
                id: number;
            };
        };
    }[];
}

interface Command {
    id: Id;
    address: {
        coordinates: {
            lat: number;
            lng: number;
        };
    };
    client: {
        id: number;
        firstName: string;
        lastName: string;
    };
}

interface Meal {
    command: {
        id: Id;
    };
    status: string;
}

const DEFAULT_LATLNG = HOME_LATLNG;

const DEFAULT_LAT = DEFAULT_LATLNG.lat;
const DEFAULT_LNG = DEFAULT_LATLNG.lng;
const DEFAULT_ZOOM = 11;

export default function DeliveryMap({
    rounds,
    commands,
    meals,
    onClientClick,
    geolocation,
    locked,
}: DeliveryMapProps) {
    const { containerRef, mapRef } = useUpdateSizeOnContainerChange();
    const [position, setPosition] = useState<[number, number]>([
        DEFAULT_LAT,
        DEFAULT_LNG,
    ]);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);

    const clients = commands
        .map((c) => ({ ...c.client, address: c.address }))
        .filter(ArrayService.filterUniqueEntity);

    const clientClusters = useClientClusters(clients, zoom);
    return (
        <div className="delivery-map" ref={containerRef}>
            {locked && <div className="__mask" />}
            <LeafletMap
                center={position}
                zoom={zoom}
                ref={mapRef}
                onZoomend={(e: any) => {
                    setZoom(e.target.getZoom());
                }}
                maxZoom={18}
                minZoom={9}
                dragging={!locked}
                touchZoom={!locked}
            >
                <TileLayer
                    url={`${BaseUrlConstants.BASE_URL}map-tiles/{z}/{x}/{y}.png`}
                />
                {clientClusters.map((c) => (
                    <LeafletClientMarker
                        key={c.key}
                        rounds={rounds}
                        meals={meals}
                        commands={commands}
                        cluster={c}
                        onClientClick={onClientClick}
                    />
                ))}
                <HomeMarker />
                <DeliveryPath deliveries={rounds} clients={clients} />
                {geolocation && <GeolocationMarker />}
            </LeafletMap>
            {geolocation && <ResetGeolocationButton onClick={setPosition} />}
        </div>
    );
}

function GeolocationMarker() {
    const { latitude, longitude } = useGeolocation();

    const el = useMemo(() => document.createElement('div'), []);
    useEffect(() => {
        return () => el.remove();
    }, [el]);

    if (latitude === null && longitude === null) {
        return null;
    }

    const position = [latitude, longitude] as [number, number];
    return (
        <>
            <Marker
                position={position}
                icon={L.divIcon({
                    html: el,
                })}
            ></Marker>
            {ReactDOM.createPortal(
                <AimOutlined className="geolocation-marker" />,
                el,
            )}
        </>
    );
}

function ResetGeolocationButton({
    onClick,
}: {
    onClick: (newPosition: [number, number]) => void;
}) {
    const { latitude, longitude } = useGeolocation();
    if (latitude === null && longitude === null) {
        return null;
    }
    return (
        <Button
            className="reset-geolocation-button"
            icon={<AimOutlined />}
            shape="circle"
            size="large"
            onClick={() => onClick([latitude, longitude] as [number, number])}
        />
    );
}

function useUpdateSizeOnContainerChange() {
    const mapRef = useRef(null);
    const [ref, { width, height }] = useMeasure();
    useEffect(() => {
        if (mapRef.current) {
            // @ts-ignore
            mapRef.current.leafletElement.invalidateSize();
        }
    }, [width, height]);
    return {
        containerRef: ref as (instance: any) => void,
        mapRef,
    };
}
