import * as React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    className?: string;
}

export default function Loading({ className }: Props) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />; //<Icon type="loading" style={{ fontSize: 24 }} spin />;
    return <Spin className={className} indicator={antIcon} />;
}
