import React, { useState, useEffect } from 'react';
import './WeekSelect.scss';
import { Tooltip, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';

interface Props {
    initialValue?: string;
    value?: string;
    startWeekDay?: number;
    onChange: (v: string) => void;
}

export default function WeekSelect({
    initialValue,
    value,
    startWeekDay,
    onChange,
}: Props) {
    const [currDay, setCurrentDay] = useState<string>(
        initialValue || moment().format('YYYY-MM-DD'),
    );
    const currentDay = value || currDay;

    useEffect(() => {
        setCurrentDay(initialValue || moment().format('YYYY-MM-DD'));
    }, [initialValue]);

    function previousWeek() {
        setCurrentDay(
            moment(currentDay)
                .subtract(1, 'weeks')
                .startOf('isoWeek')
                .format('YYYY-MM-DD'),
        );
        onChange(
            moment(currentDay)
                .subtract(1, 'weeks')
                .startOf('isoWeek')
                .format('YYYY-MM-DD'),
        );
    }

    function nextWeek() {
        setCurrentDay(
            moment(currentDay)
                .add(1, 'weeks')
                .startOf('isoWeek')
                .format('YYYY-MM-DD'),
        );
        onChange(
            moment(currentDay)
                .add(1, 'weeks')
                .startOf('isoWeek')
                .format('YYYY-MM-DD'),
        );
    }

    return (
        <div className="week-select">
            <div style={{ display: 'inline-block' }}>
                <b>Semaine {moment(currentDay).week()}</b>
                <Tooltip title="Semaine précédente">
                    <Button
                        shape="circle"
                        icon={<LeftOutlined />}
                        onClick={previousWeek}
                        style={{ float: 'left', marginRight: '60px' }}
                    />
                </Tooltip>
                <Tooltip title="Semaine suivante">
                    <Button
                        shape="circle"
                        icon={<RightOutlined />}
                        onClick={nextWeek}
                        style={{ float: 'right', marginLeft: '60px' }}
                    />
                </Tooltip>
            </div>
            <p>
                Du{' '}
                {moment(currentDay)
                    .clone()
                    .weekday(startWeekDay || 0)
                    .format('Do MMMM')}{' '}
                au{' '}
                {moment(currentDay)
                    .clone()
                    .weekday((startWeekDay || 0) + 6)
                    .format('Do MMMM YYYY')}
            </p>
        </div>
    );
}
