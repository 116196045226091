export default class StringService {
    static capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    static compareCaseInsensitive(s1: string, s2: string): number {
        const nameA = s1 ? s1.toString().toUpperCase() : '';
        const nameB = s2 ? s2.toString().toUpperCase() : '';
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }
    static pad(number: number, width: number, char?: string): string {
        char = char || '0';
        const numberStr = number + '';
        return numberStr.length >= width
            ? numberStr
            : new Array(width - numberStr.length + 1).join(char) + numberStr;
    }
}
