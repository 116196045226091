import React, { useState, useEffect } from 'react';

import { Popover, Select, Button, notification } from 'antd';
import { MoreOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import DeleteButton from 'shared/components/DeleteButton';

import './DeliveryZoneItem.scss';
import SortListItem from 'components/SortListItem';
import useDebounce from 'shared/hooks/useDebounce';

import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

interface DeliveryZoneItemProps {
    zone: DeliveryZone;
    isSelected: boolean;
    onSelect?: () => void;
    onDelete: () => void;
    onChange: (values: DeliveryZoneInput) => void;
    employees: Employee[];
    clients?: Client[];
}

export interface DeliveryZoneInput {
    id: Id;
    name?: string;
    points?: {
        lat: number;
        lng: number;
    }[];
    defaultDeliveryMan?: number | null;
    defaultClients?: {
        client: number;
        sortOrder: number;
    }[];
}

interface DeliveryZone {
    id: Id;
    name: string;
    color: string;
    points: {
        lat: number;
        lng: number;
    }[];
    defaultDeliveryMan: {
        id: number;
    } | null;
    defaultClients?: {
        id: Id;
        client: {
            id: number;
        };
        sortOrder: number;
    }[];
}

interface Employee {
    id: number;
    firstName: string;
    lastName: string;
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
}

export default function DeliveryZoneItem({
    zone,
    isSelected,
    onSelect,
    onDelete,
    onChange,
    employees,
    clients,
}: DeliveryZoneItemProps) {
    const [defaultClients, setDefaultClients] = useState(zone.defaultClients);
    const [clientsVisible, setClientsVisible] = useState(false);

    const dOnChange = useDebounce(onChange, 1000);

    useEffect(() => {
        setDefaultClients(zone.defaultClients);
    }, [zone.defaultClients]);

    const actionMenu = (
        <>
            {onSelect && (
                <Button onClick={onSelect}>
                    {isSelected ? 'Terminer' : 'Modifier'} le tracé
                </Button>
            )}
            {onSelect && clients && <OptimizeDeliveryZoneButton zone={zone} />}
            {onSelect && (
                <DeleteButton
                    icon={false}
                    onDelete={() => onChange({ id: zone.id, points: [] })}
                    className="__clear-btn"
                >
                    Effacer le tracé
                </DeleteButton>
            )}
            <DeleteButton onDelete={onDelete}>Supprimer la zone</DeleteButton>
        </>
    );

    function moveClient(dragIndex: number, hoverIndex: number) {
        if (!defaultClients) {
            return;
        }
        let newDefaultClients = defaultClients.sort((c1, c2) =>
            c1.sortOrder > c2.sortOrder ? 1 : -1,
        );
        const defaultClient = newDefaultClients.find(
            (c) => c.sortOrder === dragIndex,
        );
        if (defaultClient) {
            newDefaultClients.splice(dragIndex, 1);
            newDefaultClients.splice(hoverIndex, 0, defaultClient);
            newDefaultClients = newDefaultClients.map((c, index) => ({
                ...c,
                sortOrder: index,
            }));

            setDefaultClients(newDefaultClients);

            dOnChange({
                id: zone.id,
                defaultClients: newDefaultClients.map((c) => ({
                    client: c.client.id,
                    sortOrder: c.sortOrder,
                })),
            });
        }
    }

    return (
        <div
            className={`delivery-zone-item ${isSelected ? '--selected' : ''}`}
            style={{ borderColor: zone.color }}
        >
            <header className="__header" style={{ borderColor: zone.color }}>
                <div
                    className="__background"
                    style={{ backgroundColor: zone.color }}
                ></div>
                <div className="__title">{zone.name}</div>
                <Popover
                    content={actionMenu}
                    placement="right"
                    overlayClassName="delivery-zone-item__action-menu"
                >
                    <Button
                        icon={<MoreOutlined />}
                        className="__action-btn"
                        shape="circle"
                        size="small"
                    />
                </Popover>
            </header>
            <div className="__content">
                <div className="__default-delivery-man">
                    <span className="__label">Livreur :</span>
                    <Select
                        className="__delivery-man-choice"
                        defaultValue={zone.defaultDeliveryMan?.id}
                        onChange={(value) => {
                            onChange({
                                id: zone.id,
                                defaultDeliveryMan: value,
                            });
                        }}
                    >
                        {employees?.map((employee: Employee) => {
                            return (
                                <Select.Option
                                    value={employee.id}
                                    key={employee.id}
                                >
                                    {employee.firstName} {employee.lastName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                {defaultClients && clients && (
                    <>
                        <div>{`${defaultClients.length} client${
                            defaultClients.length > 1 ? 's' : ''
                        }`}</div>

                        {defaultClients.length > 0 && (
                            <Button
                                className="__clients-visibility-trigger"
                                onClick={() => setClientsVisible((v) => !v)}
                            >
                                {clientsVisible
                                    ? 'Masquer les clients'
                                    : 'Voir les clients'}
                                {clientsVisible ? (
                                    <UpOutlined />
                                ) : (
                                    <DownOutlined />
                                )}
                            </Button>
                        )}
                        {clientsVisible && (
                            <div className="__client-list">
                                {defaultClients
                                    .sort((c1, c2) =>
                                        c1.sortOrder > c2.sortOrder ? 1 : -1,
                                    )
                                    .map((c) => {
                                        const client = clients.find(
                                            (cl) => cl.id === c.client.id,
                                        );
                                        return (
                                            client && (
                                                <SortListItem
                                                    key={client.id}
                                                    id={client.id}
                                                    listId={zone.id}
                                                    index={c.sortOrder}
                                                    onMove={moveClient}
                                                >
                                                    {`${client.firstName} ${client.lastName}`}
                                                </SortListItem>
                                            )
                                        );
                                    })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

const OPTIMIZE_DELIVERY_ZONE = gql`
    mutation optimizeDeliveryZone($id: UUID!) {
        optimizeDeliveryZone(id: $id) {
            id
            defaultClients {
                id
                client {
                    id
                }
                sortOrder
            }
        }
    }
`;

function handleMutationError(err: Error) {
    notification.error({
        message: "Erreur lors de l'enregistrement",
        description: err.message,
    });
}
interface OptimizeDeliveryZoneButtonProps {
    zone: DeliveryZone;
}
function OptimizeDeliveryZoneButton({ zone }: OptimizeDeliveryZoneButtonProps) {
    const [optimizeDeliveryZone, { loading }] = useMutation(
        OPTIMIZE_DELIVERY_ZONE,
        {
            onError: handleMutationError,
        },
    );

    return (
        <Button
            loading={loading}
            onClick={() => optimizeDeliveryZone({ variables: { id: zone.id } })}
        >
            Calculer le trajet
        </Button>
    );
}
