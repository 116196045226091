import React from 'react';
import { Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
    onDelete: () => void;
    size?: 'large' | 'middle' | 'small';
    shape?: string;
    className?: string;
    children?: string;
    icon?: any | false;
}

export default function DeleteButton({
    onDelete,
    shape,
    className,
    children,
    size,
    icon: pIcon,
}: Props) {
    const icon = pIcon === false ? undefined : pIcon || <DeleteOutlined />;

    return (
        <Popconfirm
            title="Êtes-vous sûr ?"
            onConfirm={onDelete}
            okText="Ok"
            cancelText="Annuler"
        >
            {shape === 'circle' ? (
                <Button
                    danger
                    shape="circle"
                    icon={icon}
                    className={className}
                    size={size}
                />
            ) : (
                <Button
                    icon={icon}
                    type="primary"
                    danger
                    size={size}
                    className={className}
                    shape="round"
                >
                    {children || 'Supprimer'}
                </Button>
            )}
        </Popconfirm>
    );
}
