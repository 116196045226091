import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';

declare interface ResetPasswordFormProps {
    fields: any[];
    onChange: (fields: any[]) => void;
    onSubmit: (plainPassword: string) => void;
    loading: boolean;
}

export default function ResetPasswordForm({ fields, onChange, onSubmit, loading }: ResetPasswordFormProps) {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();

    useEffect(() => {
        forceUpdate({});
    }, []);

    function handleSubmit(values: any) {
        if (onSubmit) {
            const { plainPassword } = values;
            onSubmit(plainPassword);
        }
    }

    return (
        <Form
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
        >
            Mot de passe
            <Form.Item
                name="plainPassword"
                rules={[{ required: true, message: 'Ce champ est requis' }]}
            >
                <Input.Password />
            </Form.Item>
            Répétez le mot de passe
            <Form.Item
                name="confirmPassword"
                rules={[
                    {
                        required: true,
                        message: 'Ce champ est requis',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (
                                !value ||
                                getFieldValue('plainPassword') === value
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                'Les mots de passe ne correspondent pas.',
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    shape="round"
                >
                    Mettre à jour mon mot de passe
                </Button>
            </Form.Item>
        </Form>
    );
}